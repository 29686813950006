<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${notes.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                :color="rha.save_money ? 'brown' : ''"
                dark
                @click="saveMoneyDlg"
              >
                <v-icon> mdi-circle-opacity </v-icon>
              </v-btn>
            </template>
            <span
              v-text="
                'Ahorro ' +
                (rha.save_money ? numberFormat(rha.save_money_amount) : '')
              "
            />
          </v-tooltip>
          <v-tooltip
            v-if="
              login.permissions.rha_benefit_details_amounts &&
              login.permissions.rha_benefit_details_amounts.read
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                :color="rha_benefit_details.length > 0 ? 'pink' : ''"
                dark
                :to="{
                  name: page_route
                    ? page_route + '.rha_benefit_details_amounts'
                    : '',
                  params: { id: id },
                }"
                :loading="rha_benefit_details_ldg"
              >
                <v-icon> mdi-cash-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Montos'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              login.permissions.rha_benefit_details_checks &&
              login.permissions.rha_benefit_details_checks.read
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                :color="rha_benefit_details.length > 0 ? 'teal' : ''"
                dark
                :to="{
                  name: page_route
                    ? page_route + '.rha_benefit_details_checks'
                    : '',
                  params: { id: id },
                }"
                :loading="rha_benefit_details_ldg"
              >
                <v-icon> mdi-clipboard-multiple </v-icon>
              </v-btn>
            </template>
            <span v-text="'Comp. insumos'" />
          </v-tooltip>
          <BtnCircle
            v-if="
              $store.getters.getLogin.permissions.rha_administratives.create
            "
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="handleDialogAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form_data" lazy-validation>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Fecha</th>
                            <th class="text-left">Plan de tratamiento</th>
                            <th class="text-left">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in notes" :key="i">
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.description }}</td>
                            <td>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_administratives.update
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="editNote(item.id)"
                                  >
                                    <v-icon v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Editar'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_administratives.delete
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="error"
                                    dark
                                    @click="HandleDesactivate(item.id)"
                                  >
                                    <v-icon v-text="'mdi-delete'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Eliminar'" />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- modal update photo -->
    <div>
      <v-dialog
        v-model="modal_add"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar dark>
            <v-btn icon dark @click="handleDialogAdd">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-text="'Agregar'" />
            <v-spacer />
            <v-toolbar-items>
              <v-btn dark text @click="handleSubmit">Aceptar</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form v-on:submit.prevent ref="form_add" lazy-validation>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="6">
                    <v-textarea
                      label="Plan de tratamiento"
                      v-model="note.description"
                      :rules="rules.required"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="save_money_dlg" scrollable persistent max-width="500">
      <v-card tile :disabled="save_money_dlg_ldg" :loading="save_money_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> AHORRO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="save_money_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="save_money">
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12">
              <v-form v-on:submit.prevent ref="save_money_form" lazy-validation>
                <v-row dense>
                  <v-col cols="12" xs="12" md="6">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="
                        'Activo: ' + (save_money.save_money ? 'SI' : 'NO')
                      "
                      v-model="save_money.save_money"
                      dense
                    />
                  </v-col>
                  <v-col v-if="save_money.save_money" cols="12" xs="12" md="6">
                    <v-text-field
                      label="Monto"
                      v-model="save_money.save_money_amount"
                      type="number"
                      dense
                      min="0"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      small
                      block
                      color="brown"
                      dark
                      @click.prevent="saveMoney"
                    >
                      Guardar
                      <v-icon right> mdi-send </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
//import functions
//import components
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  numberFormat,
} from "../../control";
import { show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      numberFormat: numberFormat,
      page_route: null,
      page_title: "Notas catastroficas",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: true,
      data: {
        amount_2: 7000.0,
      },
      rules: rules(),
      values: [
        {
          date: "2021-10-01 12:00:00",
          observation: "Observación 1",
        },
        {
          date: "2021-10-02 12:00:00",
          observation: "Observación 2",
        },
        {
          date: "2021-10-03 12:00:00",
          observation: "Observación 3",
        },
      ],
      modal_add: false,
      notes: [],
      note: {},
      rha: {},
      rha_id: this.$attrs.id,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      rha_benefit_details: [],
      rha_benefit_details_ldg: true,
      save_money_dlg: false,
      save_money_dlg_ldg: false,
      save_money: null,
    };
  },
  methods: {
    getNotes() {
      index("rha/catastrophics", this.login.token, [
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.notes = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
        this.modal_add = false;
      });
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              Axios.post(
                URL_API + "/rha/catastrophics",
                this.note,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.note = {
                    rha_id: this.$attrs.id,
                  };
                  this.getNotes();
                } else console.log(response.data.message);
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    HandleDesactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "rha/catastrophics",
              this.login.token,
              id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.getNotes()
                : console.log(response.message);
            });
          }
        });
    },
    editNote(id) {
      show("rha/catastrophics", this.login.token, id).then((response) => {
        this.note = response;
        this.modal_add = true;
      });
    },
    handleDialogAdd() {
      this.modal_add = !this.modal_add;
    },
    addDocumento() {
      this.note.documents.push({
        id: null,
        desciption: "",
        document_id: 0,
        active: true,
      });
    },
    deleteDocument(i) {
      if (this.note.documents[i].id === null) this.note.documents.splice(i, 1);
      else this.note.documents[i].active = false;
    },
    saveMoneyDlg() {
      this.save_money = {
        save_money: this.rha.save_money,
        save_money_amount: this.rha.save_money_amount,
        rha_id: this.id,
      };
      this.save_money_dlg_ldg = false;
      this.save_money_dlg = true;
    },
    saveMoney() {
      if (this.$refs.save_money_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.save_money_dlg_ldg = true;

              Axios.post(
                URL_API + "/rhas/save_money",
                this.save_money,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.rha.save_money = this.save_money.save_money;
                  this.rha.save_money_amount =
                    this.save_money.save_money_amount;
                  this.save_money_dlg = false;
                  this.getNotes();
                }

                this.save_money_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;
    });

    Axios.get(
      URL_API + "/rhas/" + this.id + "/benefit_details",
      headersToken(this.login.token)
    ).then((res) => {
      this.rha_benefit_details = res.data.data;
      this.rha_benefit_details_ldg = false;
    });

    this.getNotes();
    this.note.rha_id = this.$attrs.id;
  },
};
</script>